e
<template>
  <div class="joinActive">
    <ShowVue :activeInfo="activeInfo" />
    <el-card class="box-card">
      <h3>参与秒杀活动</h3>
      <!-- 时间筛选 -->
      <div class="filter">
        <el-button v-if="type == 'add'" type="primary" @click="addFlashShop"
          >添加秒杀商品</el-button
        >
      </div>
      <!-- 分时段活动 -->
      <el-tabs
        v-model="timeFrameId"
        v-if="activeInfo.type"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          :name="item.id + ''"
          v-for="(item, index) in activeInfo.activityTimeFrameList"
          :key="item.id"
          :label="`${item.timeFrameStart} - ${item.timeFrameEnd}`"
        >
          <tableListVue
            @deleteGoods="deleteGoods"
            v-if="type == 'add'"
            :dataList="dataList[index]"
            :dataListLoading="dataListLoading"
            :type="type"
          >
          </tableListVue>
          <tableListVue
            v-if="type == 'check'"
            :type="type"
            :dataList="dataDetailList[index].activityProductInfoList"
            :dataListLoading="dataListLoading"
          >
          </tableListVue>
        </el-tab-pane>
      </el-tabs>
      <!-- 全时段表格 -->
      <tableListVue
        @deleteGoods="deleteGoods"
        v-if="!activeInfo.type"
        :dataList="dataList"
        :dataListLoading="dataListLoading"
        :type="type"
      >
      </tableListVue>
      <!-- 审核按钮 -->
      <div class="auditing" v-if="type == 'add'">
        <el-button type="primary" @click="submitAuditing">提交审核</el-button>
      </div>
    </el-card>
    <el-dialog
      title="选择商品 "
      width="70%"
      :visible.sync="shopTableVisible"
      center
    >
      <el-form :inline="true" size="small">
        <el-form-item label="商品规格名称">
          <el-input v-model="dataForm.productDetailGroupName"></el-input
        ></el-form-item>
        <el-form-item label="商品规格ID">
          <el-input v-model="dataForm.repositoryId"> </el-input
        ></el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="shopList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        height="54vh"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="productId" label="商品ID"> </el-table-column>
        <el-table-column
          prop="productName"
          label="商品名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="商品图片" width="160">
          <template slot-scope="scope"
            ><img width="80" height="80" :src="scope.row.imageUrl"
          /></template>
        </el-table-column>
        <el-table-column prop="productRepositoryId" label="规格ID">
        </el-table-column>
        <el-table-column prop="productDetailGroupName" label="规格">
        </el-table-column>
        <el-table-column prop="count" label="库存"> </el-table-column>
        <el-table-column prop="personalPrice" label="成本价（元）">
        </el-table-column>
        <el-table-column prop="price" label="零售价（元）"> </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="enterAdd">确 定</el-button>
      </span>
      <div class="pagination-box">
        <el-pagination
          :current-page.sync="dataForm.pageNo"
          :page-size="dataForm.pageSize"
          :page-sizes="[10, 20, 30]"
          :total="total"
          background
          layout="total, sizes,prev, pager, next,jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ShowVue from './components/Show.vue';
import tableListVue from './components/tableList.vue';
export default {
  name: 'joinActive',
  components: {
    ShowVue,
    tableListVue,
  },
  created() {
    this.activityTimeFrameList = this.$route.query.row.activityTimeFrameList;
    // 如果分时段 默认选中第一个时间段
    if (this.activeInfo.type) {
      this.timeFrameId = this.activityTimeFrameList[0].id + '';
      // 初始化数据列表
      for (let i = 0; i < this.activityTimeFrameList.length; i++) {
        this.dataList.push([]);
        this.dataDetailList.push([]);
      }
    }
    // 如果是查看详情调用该接口
    if (this.type == 'check') {
      this.getGoodsDetail();
    }
  },
  data() {
    return {
      params: {},
      dataList: [], //添加商品数据列表
      dataDetailList: [], //详情商品数据列表
      shopList: [],
      dataListLoading: false,
      activeInfo: Object.assign({}, this.$route.query.row), //活动信息
      type: this.$route.query.type,
      shopTableVisible: false,
      flashTime: '', //时间筛选
      timeFrameId: '', //时间筛选ID
      multipleSelection: [], //多选数组
      activityTimeFrameList: [],
      total: 0,
      dataForm: {
        pageNo: 1,
        pageSize: 10,
        productDetailGroupName: null,
        repositoryId: null,
      },
    };
  },

  methods: {
    handleClick() {},

    // 删除商品
    deleteGoods(id) {
      // 分时段
      if (this.activeInfo.type) {
        this.activeInfo.activityTimeFrameList.forEach((item, index, arr) => {
          // 如果选中时间ID与表格对应 则删除数据
          if (this.timeFrameId == arr[index].id) {
            this.dataList[index] = this.dataList[index].filter(
              (item) => item.productRepositoryId != id
            );
          }
        });
      } else {
        // 全时段
        this.dataList = this.dataList.filter(
          (item) => item.productRepositoryId != id
        );
      }
      this.$forceUpdate();
    },
    // 请求商品详情数据
    async getGoodsDetail() {
      const res = await this.$api.active.reqGoodsDetail({
        marketingId: this.activeInfo.id,
        timeFrameId: this.activeInfo.type == 0 ? '' : this.timeFrameId,
      });
      // console.log(res);
      if (res.code == 200) {
        if (this.activeInfo.type) {
          // 分时段详情列表
          this.dataDetailList = res.data.activityTimeFrameList || [];
        } else {
          // 全时段详情列表
          this.dataList = res.data.activityProductInfoList || [];
        }
      } else {
        this.dataDetailList = [];
        this.dataList = [];
        this.$message.error(res.message);
      }
    },

    // 添加秒杀商品
    async addFlashShop() {
      this.shopTableVisible = true;
      const res = await this.$api.active.reqAddGoods({
        marketingId: this.activeInfo.id,
        timeFrameId: this.activeInfo.type == 0 ? '' : this.timeFrameId,
        ...this.dataForm,
      });
      if (res.code == 200) {
        this.shopList = res.data.list || [];
        this.total = Number(res.data.total);
      } else {
        this.shopList = [];
        this.total = 0;
        this.$message.error(res.message);
      }
    },

    query() {
      //查询
      this.addFlashShop();
    },
    reset() {
      this.dataForm.pageNo = 1;
      this.dataForm.pageSize = 10;
      this.dataForm.productDetailGroupName = null;
      this.dataForm.repositoryId = null;
      this.addFlashShop();
    },

    // 确定添加
    enterAdd() {
      this.shopTableVisible = false;
      // 分时段添加
      if (this.activeInfo.type) {
        this.activeInfo.activityTimeFrameList.forEach((item, index, arr) => {
          // 如果选中时间ID与表格对应 则添加数据
          if (this.timeFrameId == arr[index].id) {
            const hasDuplicate = this.dataList[index].some((obj1) =>
              this.multipleSelection.some(
                (obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)
              )
            );
            if (!hasDuplicate) {
              this.dataList[index].push(...this.multipleSelection);
            } else {
              this.$message.warning('请勿重新添加商品');
            }
          }
        });
      } else {
        // 全时段添加
        const hasDuplicate = this.dataList.some((obj1) =>
          this.multipleSelection.some(
            (obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)
          )
        );
        if (!hasDuplicate) {
          this.dataList.push(...this.multipleSelection);
        } else {
          this.$message.warning('请勿重新添加商品');
        }
      }
    },

    // 提交审核
    async submitAuditing() {
      const isGooods = this.dataList.some((item) => {
        if (!item || item.length == 0) {
          return false;
        } else {
          return true;
        }
      }); //判断任何时间段是否有添加商品

      if (!isGooods) return this.$message.warning('请添加商品');
      // 分时段
      if (this.activeInfo.type) {
        let activityTimeFrameList = [];
        this.dataList.forEach((item, index) => {
          activityTimeFrameList.push({
            id: this.activityTimeFrameList[index].id,
            activityProductInfoList: item,
          });
        });

        activityTimeFrameList.forEach((item, index) => {
          item.activityProductInfoList = item.activityProductInfoList.map(
            (itm, idx, arr) => {
              return (item = {
                seckillPrice: itm.seckillPrice * 100 + '',
                seckillQuantity: itm.seckillQuantity + '',
                productId: itm.productRepositoryId,
              });
            }
          );
        });
        const res = await this.$api.active.reqAddGoodsAuditing({
          marketingId: this.activeInfo.id,
          activityTimeFrameList,
        });
        if (res.code == 200) {
          this.shopList = res.data || [];
          this.$message.success(res.message);
          this.$router.push('resourceActive');
        } else {
          this.shopList = [];
          this.$message.error(res.message);
        }
      } else {
        // 全时段
        let activityProductInfoList = this.dataList.map((item) => {
          return {
            productId: item.productRepositoryId,
            seckillPrice: item.seckillPrice * 100 + '',
            seckillQuantity: item.seckillQuantity + '',
          };
        });
        const res = await this.$api.active.reqAddGoodsAuditing({
          marketingId: this.activeInfo.id,
          activityProductInfoList,
        });
        if (res.code == 200) {
          this.shopList = res.data || [];
          this.$message.success(res.message);
          this.$router.push('resourceActive');
        } else {
          this.shopList = [];
          this.$message.error(res.message);
        }
      }
    },

    // 多选商品
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      // 条数改变的回调
      this.dataForm.pageSize = val;
      this.addFlashShop();
    },

    handleCurrentChange(val) {
      // 当前页改变的回调
      this.dataForm.pageNo = val;
      this.addFlashShop();
    },
  },
};
</script>
<style scoped lang="less">
.show_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .show_item {
    width: 33%;
    height: 30px;
    margin-top: 10px;
    display: flex;
    span {
      color: #5a5a5a;
      flex: 1;
    }
  }
}
.filter {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 15px 0;
}

.w {
  width: 100% !important;
  padding: 10px 0;
}
.auditing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
</style>
